import React from 'react';
import Cards from 'react-credit-cards';
import {useState} from "react";
import 'react-credit-cards/es/styles-compiled.css';
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "./utils";

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
    Container,
    Card,
    TextField,
    Button,
    Box,
    IconButton,
    CircularProgress,
    Backdrop,
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    CardMedia,
    CardHeader,
    Typography,
    CardContent
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    card: {
        padding: 5,
        backgroundColor: "#fff"
    },
    amountInput: {
        textAlign: "center"
    },
    cardHeader: {
        textAlign: "center"
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function App() {
    const classes = useStyles();

    const [cvc, setCvc] = useState("")
    const [expiry, setExpiry] = useState("")
    const [focus, setFocus] = useState("")
    const [name, setName] = useState("")
    const [number, setNumber] = useState("")

    const [amountField, setAmountField] = useState(null)
    const [issuer, setIssuer] = useState("")
    const [form, setForm] = useState({})
    const [spinner, setSpinner] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorModal, setErrorModal] = useState(false);
    let setAmount = (amount) => {
        let inputField = amountField.getElementsByTagName("input")[0]
        inputField.value = amount
    }
    const handleInputChange = ({target}) => {
        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        }

        const {name, value} = target;
        switch (name) {
            case "number":
                setNumber(value)
                break
            case "cvc":
                setCvc(value)
                break
            case "name":
                setName(value)
                break
            case "expiry":
                setExpiry(value)
                break
            default:
                break
        }
    };
    const handleInputFocus = (e) => {
        setFocus(e.target.name);
    }
    const handleCallback = ({issuer}, isValid) => {
        if (isValid) {
            setIssuer(issuer)
        }
    };
    const showSuccessModal = (amount) => {
        setSuccessMessage("Thank you for your donation of " + amount + "!")
        setSuccessModal(true)
    }
    const showErrorModal = (message) => {
        setErrorMessage(message)
        setErrorModal(true)
    }
    const showSpinner = () => {
        setSpinner(true)
    }
    const hideSpinner = () => {
        setSpinner(false)
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const formData = [...e.target.elements]
            .filter(d => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value;
                return acc;
            }, {});
        const amount = formData.amount
        if (amount === "$0.00") {
            alert("Please select or enter the amount you'd like to donate.")
            return
        }
        console.log({issuer, ...formData})
        try {
            showSpinner()
            const result = await fetch("/api/charge",{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({issuer, ...formData})
            })
            let {success} = await result.json()
            if (success) {
                hideSpinner()
                showSuccessModal(amount)
            }
        } catch (e) {
            showErrorModal("Sorry there may have been an error processing you're transaction, please verify with your cardholder and try again later.")
        }

        form.reset();
    };
    const format = (value) => {
        let onlyDigits = value.toString().replace(/\D/g, "")
        let withoutLeadingZeros = ""
        let hitDigit = false
        for (let digit of onlyDigits.split("")) {
            if (digit === "0" && hitDigit === false) {
                continue
            }
            hitDigit = true
            withoutLeadingZeros += digit
        }
        onlyDigits = withoutLeadingZeros
        if (onlyDigits.length < 3) {
            if (onlyDigits.length === 2) {
                return "$0." + onlyDigits
            } else if (onlyDigits.length === 1) {
                return "$0.0" + onlyDigits
            } else {
                return "$0.00"
            }
        } else {
            let dollars = onlyDigits.substr(0, onlyDigits.length - 2)
            let cents = onlyDigits.substr(onlyDigits.length - 2)
            let segments = []
            for (let i = dollars.length - 3; i > -3; i -= 3) {
                let segment
                if (i > 0) segment = dollars.substr(i, 3)
                else segment = dollars.substr(0, 3 + i)
                segments.unshift(segment)
            }
            return "$" + segments.join(",") + "." + cents
        }
    }
    return (
        <div className={classes.root}>
            <Container maxWidth="sm">
                <Card className={classes.card} variant={"outlined"}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Adelphia Yeshiva Donation Page"
                    />
                    <CardMedia
                        component="img"
                        alt="Students Learning at Adelphia Yeshiva"
                        image={process.env.PUBLIC_URL +  "/students.png"}
                        title="Students Learning"
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Adelphia Yeshiva is a place where students grow in torah learning and gain valuable life skills.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card} variant={"outlined"}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="What would you like to donate today?"
                    />
                    <form ref={(el) => setForm(el)} onSubmit={handleSubmit}>
                        <Grid justify={"center"} container spacing={5}>
                            <Grid item xs={6}>
                                <Container justify={"center"}>
                                    <Button color={"primary"} onClick={() => {
                                        setAmount("$72.00")
                                    }}>
                                        $72 - Keep the lights on for a month.
                                    </Button>
                                </Container>
                            </Grid>

                            <Grid item xs={6}>
                                <Container justify={"center"}>
                                    <Button color={"primary"} onClick={() => {
                                        setAmount("$180.00")
                                    }}>
                                        $180 - _____
                                    </Button>
                                </Container>
                            </Grid>

                            <Grid item xs={6}>
                                <Container justify={"center"}>
                                    <Button color={"primary"} onClick={() => {
                                        setAmount("$250.00")
                                    }}>
                                        $250 - ____
                                    </Button>
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container justify={"center"}>
                                    <Button color={"primary"} onClick={() => {
                                        setAmount("$360.00")
                                    }}>
                                        $360 - A/C Heating
                                    </Button>
                                </Container>
                            </Grid>
                        </Grid>
                        <Grid justify="center" alignItems={"center"} container spacing={5}>
                            <Grid className={classes.amountInput} item xs={7}>
                                <TextField
                                    ref={(el) => setAmountField(el)}
                                    className={classes.amountInput}
                                    variant="outlined"
                                    id="amount"
                                    name="amount"
                                    prefix="$"
                                    placeholder="$0.00"
                                    required
                                    onChange={(e) => {
                                        e.target.value = format(e.target.value)
                                        setAmount(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Cards
                                    cvc={cvc}
                                    expiry={expiry}
                                    focused={focus}
                                    name={name}
                                    number={number}
                                    callback={handleCallback}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid container justify={"center"} spacing={2}>


                                    <Grid item xs={11}>
                                        <TextField
                                            variant="outlined"
                                            type="tel"
                                            name="number"
                                            className="form-control"
                                            placeholder="Card Number"
                                            pattern="[\d| ]{16,22}"
                                            autoComplete="cc-number"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Name"
                                            autoComplete="cc-name"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField
                                            variant="outlined"
                                            type="tel"
                                            name="expiry"
                                            className="form-control"
                                            placeholder="Valid Thru"
                                            pattern="\d\d/\d\d"
                                            required
                                            autoComplete="cc-exp"
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            type="tel"
                                            name="cvc"
                                            className="form-control"
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            required
                                            autoComplete="cc-csc"
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Grid>


                                </Grid>


                            </Grid>
                            <Grid item>
                                <Button variant={"contained"} type="submit" color="primary">Donate</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <input type="hidden" name="issuer" value={issuer}/>
                            </Grid>
                        </Grid>
                    </form>

                </Card>

            </Container>
            <Backdrop className={classes.backdrop} open={spinner}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={successModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {setSuccessModal(false)}}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Tizku L'Mitzvos!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {successMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setSuccessModal(false)}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={errorModal}
                keepMounted
                onClose={() => {setErrorModal(false)}}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setErrorModal(false)}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default App;
