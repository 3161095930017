import Payment from "payment";

function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
    let month, year;
    if (value.indexOf("-") !== -1) {
        month = value.split("-")[1]
        year = value.split("-")[0]
        year = clearNumber(year)
        month = clearNumber(month)
        if (year.length > 2) {
            year = year.slice(year.length - 2)
        }
    } else if (value.indexOf("/") !== -1) {
        month = value.split("/")[0]
        year = value.split("/")[1]
        year = clearNumber(year)
        month = clearNumber(month)
        if (year.length > 2) {
            month += year.slice(0, year.length - 2)
            year = year.slice(year.length - 2)
        }
    } else {
        value = value.replace(/\D+/g, "")
        if (value.length <= 2) {
            month = ""
            year = value
        } else if (value.length === 3) {
            year = value.slice(1)
            month = "0" + value.slice(0, 1)
        } else if (value.length === 4) {
            year = value.slice(2)
            month = value.slice(0, 2)
        }
    }


    if (month.length > 2) {
        month = month.slice(month.length - 2)
    }

    if (month.length === 1) {
        month = "0" + month
    }

    return month + "/" + year;
}

export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}
